import Axios from 'axios';
import React from 'react';
import { ethers } from 'ethers';
import { defaultAbiCoder } from 'ethers/lib/utils';

import env from "react-dotenv";

import {
    Navbar,
    Form,
    FormControl,
    Button,
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import logo from '../logo.png';
import TimelockABI from '../assets/TimelockABI.json';
import TXItem from '../components/TXItem';
import FuncTXItem from '../components/FuncTXItem';
import { txFormatHandler } from '../utils/execUtil';
import web3 from 'web3';

dayjs.extend(duration)

let timelockABI = new ethers.utils.Interface(TimelockABI);
let provider = new ethers.providers.JsonRpcProvider(env.RPC_URL);
let timelock;

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timeDelay: "",
            transactions: [],
            timelockContract: "0x446ce1fcff45f2a9066b7e2b4198db48e79e1bf0",
        };
        this.getTransactions = this.getTransactions.bind(this);
    }

    nthIndex(str, pat, n) {
        var L = str.length, i = -1;
        while (n-- && i++ < L) {
            i = str.indexOf(pat, i);
            if (i < 0) break;
        }
        return i;
    }

    async multisigTX(tx) {
        if (tx.input.length >= 10) {
            const funcSig = tx.input.substring(0, 10);
            console.log(funcSig)
            let resp = await Axios.get(`https://www.4byte.directory/api/v1/signatures/?hex_signature=${funcSig}`)
            console.log("multisig")
            let functionName = resp.data.results[0].text_signature
            return await txFormatHandler(tx, functionName)
        } else {
            return await txFormatHandler(tx, "")
        }

    }

    async txItemFromTX(tx) {
        let { timeStamp, from, input } = tx;
        let funcSig = input.substring(0, 10);
        let fragment;
        try {
            fragment = timelockABI.getFunction(funcSig)
        } catch (err) {
            return await this.multisigTX(tx)
        }

        let functionName = fragment.format("full").substring(9);
        const funcEnd = this.nthIndex(functionName, ")", 1) + 1;
        functionName = functionName.substring(0, funcEnd);
        let decodedData = timelockABI.decodeFunctionData(fragment, input);
        let decoded = decodedData.map(data => "" + data);
        if (functionName == "queueTransaction(address target, uint256 value, string signature, bytes data, uint256 eta)" || functionName == "executeTransaction(address target, uint256 value, string signature, bytes data, uint256 eta)") {
            const startIdx = decoded[2].indexOf("(");
            const endIdx = decoded[2].indexOf(")");
            const args = decoded[2].substring(startIdx + 1, endIdx).split(",");
            console.log(args);
            console.log(decoded[3])
            var decodedFunc = ethers.utils.defaultAbiCoder.decode(args, decoded[3])
            console.log(decodedFunc)
            return {
                hash: tx.hash,
                functionCall: functionName.indexOf("queue") != -1 ? "Transaction queued" : "Transaction executed",
                data: decodedData.join(", "),
                decodedData: decoded,
                internalFunc: decoded[2],
                internalFuncData: decodedFunc,
                timeStamp,
                from,
            }
        } else {
            return {
                hash: tx.hash,
                functionCall: functionName,
                data: decoded.join(", "),
                decodedData: decoded || decodedData[0],
                timeStamp,
                from,
            }
        }
    }

    getTransactions() {
        this.transactions().then(txObjs => {
            console.log(txObjs)
            this.setState({
                transactions: txObjs.reverse(),
            });
        })
    }

    async transactions() {
        const address = this.state.timelockContract;
        let timelockInfo = await this.getTimelockInfo();
        this.setState({
            timeDelay: timelockInfo.delay,
            adminAddress: timelockInfo.admin,
        });


        let resp = await Axios.get(`https://api.etherscan.io/api?module=account&action=txlist&address=${address}&sort=asc&apikey=${env.ETHERSCAN_API}`)

        let txObjs = [];
        for (let i = 0; i < resp.data.result.length; i++) {
            let tx = resp.data.result[i];
            let txItem = await this.txItemFromTX(tx);
            if (txItem) txObjs.push(txItem)
        }
        return txObjs
    }

    async getTimelockInfo() {
        timelock = new ethers.Contract(this.state.timelockContract, TimelockABI, provider)
        try {
            let delay = await timelock.functions.delay();
            let admin = await timelock.functions.admin();

            let delayReadable = Number(delay.toString())
            return { delay: dayjs.duration({ seconds: delayReadable }).humanize(), admin };
        } catch (err) {
            console.log("not a timelock")
            return {}
        }
    }

    render() {
        return (
            <div className="App">
                <Navbar bg="light" expand="lg">
                    <img src={logo} height={48} width={48} />
                    <Navbar.Brand>
                        Timelock.FYI
                    </Navbar.Brand>
                </Navbar>
                <Container>
                    <Row style={{ alignItems: "center", padding: 16, }}>
                        <Form inline>
                            <FormControl style={{ width: 380 }} type="text" placeholder="Timelock Contract" className="mr-sm-2" value={this.state.timelockContract} onChange={txt => {
                                console.log(txt.target.value)
                                this.setState({ timelockContract: txt.target.value })
                            }} />
                            <Button variant="outline-success" onClick={this.getTransactions}>FYI</Button>
                        </Form>
                        <Col>Time Delay: <br />{this.state.timeDelay}</Col>
                        <Col>Owner: <br />{this.state.adminAddress}</Col>
                        <Col></Col>
                    </Row>
                </Container>
                {this.state.transactions.map((tx, index) => {
                    if (tx.internalFunc) {
                        return FuncTXItem(index, tx, false)
                    } else {
                        return TXItem(index, tx, false)
                    }
                    return null
                })}
            </div>
        );
    }
}

export default Main;

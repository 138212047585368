import logo from './logo.png';
import './App.css';
import Main from './pages/Main';

function App() {
  return (
    <Main />
  );
}

export default App;

import Axios from 'axios';
import { ethers } from 'ethers';

var oneEther = ethers.BigNumber.from("1000000000000000000");

const functionCases = {
  "execTransaction(address,uint256,bytes,uint8,uint256,uint256,uint256,address,address,bytes)": async (tx, functionName) => {
    const { hash, timeStamp, from, input } = tx;
    let result = decodedArgsFromFuncSig(functionName, input)
    console.log(result)
    let decoded = result.map(data => "" + data);

    console.log("decoded:")
    console.log(decoded)

    const innerFuncSig = decoded[2].substring(0, 10);
    console.log(innerFuncSig)
    let resp = await Axios.get(`https://www.4byte.directory/api/v1/signatures/?hex_signature=${innerFuncSig}`)
    if (resp.data.results.length == 0) {
      return {
        hash: hash,
        functionCall: functionName,
        data: result.join(", "),
        decodedData: decoded,
        internalFunc: innerFuncSig,
        internalFuncData: decoded[2].substring(10),
        timeStamp,
        from,
      }
    } else {
      let functionName = resp.data.results[0].text_signature
      const startIdx = functionName.indexOf("(");
      const endIdx = functionName.indexOf(")");
      const innerArgs = functionName.substring(startIdx + 1, endIdx).split(",");
      console.log(innerArgs);
      let decodedArgs = [];
      if (innerArgs > 0 && decoded[2].length > 10) {
        decodedArgs = ethers.utils.defaultAbiCoder.decode(innerArgs, ethers.utils.hexDataSlice(decoded[2], 4))
      }
      console.log(decodedArgs)
      return {
        hash: hash,
        functionCall: functionName.indexOf("queue") != -1 ? "Transaction queued" : "Transaction executed",
        data: result.join(", "),
        decodedData: decoded,
        internalFunc: innerFuncSig,
        internalFuncData: decodedArgs,
        timeStamp,
        from,
      }
    }
  },
  // "withdraw(address,bytes,bytes32,bytes32,address,address,uint256,uint256)": async (tx, functionName) => {
  //   const { hash, timeStamp, from, input } = tx;
  //   let result = decodedArgsFromFuncSig(functionName, input)
  //   console.log(result)
  //   let decoded = result.map(data => "" + data);

  //   console.log("decoded:")
  //   console.log(decoded)

  //   return {
  //     hash: hash,
  //     functionCall: functionName,
  //     data: result.join(", "),
  //     decodedData: decoded,
  //     timeStamp,
  //     from,
  //   }
  // },
  "": async (tx, functionName) => {
    const { hash, timeStamp, to, from, value } = tx;
    const weiBn = ethers.utils.parseUnits(value, "wei");
    return {
      hash,
      functionCall: `Received ${weiBn.div(oneEther)} ETH from ${from}`,
      timeStamp,
      from,
    }
  }
}

export async function txFormatHandler(tx, functionName) {
  const { hash, timeStamp, from } = tx;

  const functionCase = functionCases[functionName];
  console.log("Handling: " + functionName)
  if (!functionCase) {
    let decoded = decodedArgsFromFuncSig(functionName, tx.input).map(data => "" + data);
    return {
      hash: hash,
      functionCall: functionName,
      data: decoded.join(", "),
      decodedData: decoded || decoded[0],
      timeStamp,
      from,
    }
  } else {
    let funcCase = await functionCase(tx, functionName)
    console.log(funcCase)
    return funcCase
  }
}

export function decodedArgsFromFuncSig(functionName, input) {
  const paramsStart = functionName.indexOf("(") + 1;
  const paramsEnd = functionName.indexOf(")");
  const params = functionName.substring(paramsStart, paramsEnd).split(",");
  if (input.length > 10) {
    let result = ethers.utils.defaultAbiCoder.decode(
      params, // TODO: make this check for named params.
      ethers.utils.hexDataSlice(input, 4)
    );
    return result;
  }

  return [];
}